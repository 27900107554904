import React, { useState } from 'react';
import styles from './AdminSidebar.module.scss'
import { SidebarModel } from '../../interface/Sidebar';
import { URLRoutes } from '../../enums/Routes';
import { useHistory, useLocation } from 'react-router-dom';
import AdminSidebarItem from './AdminSidebarItem/AdminSidebarItem';

const AdminSidebar = () => {
    const history = useHistory();
    const location = useLocation();
    const [adminSidebar, setAdminMenuState] = useState({
        sidebarAdminMenu: [
            { label: 'Transactions', icon: 'icon_list_admin', active: true, url: [URLRoutes.Transactions] },
            { label: 'Users', icon: 'icon_users', active: false, url: [URLRoutes.Users] },
            { label: 'Exchange rate', icon: 'icon_exchange_rate', active: false, url: [URLRoutes.ExchangeRate] },
            { label: 'Commission fee', icon: 'icon_percentage', active: false, url: [URLRoutes.CommissionFee] },
            { label: 'Admins', icon: 'icon_admins', active: false, url: [URLRoutes.Admins] },
            { label: 'Options Payment', icon: 'icon_options', active: false, url: [URLRoutes.PaymentOptions] },
            { label: 'Options Payout', icon: 'icon_options', active: false, url: [URLRoutes.PayoutOptions] },
            { label: 'Purpose of transfer', icon: 'icon_options', active: false, url: [URLRoutes.PurposeOfTransfer] },
        ]
    });


    const selectAdminMenuItem = (item: SidebarModel, index: number) => {
        setAdminMenuState({
            sidebarAdminMenu: adminSidebar.sidebarAdminMenu.map((menu, indexItem) => ({
                ...menu,
                active: index === indexItem && item.label === menu.label
            }))
        });

        if (item.url) {
            history.replace(item.url[0]); // one sidebar item can have more than one page, so we open the first from the array as default
        }
    }

    return <div className={`${styles.sidebar_wrapper}`}>
        <div className={` ${styles.sidebar_first_menu}`}>
            <div className={styles.sidebar_logo}>
                <span className="icon icon_beoexport"></span>
            </div>
            <div className={styles.menu_container}>
                <div className={styles.sidebar_menu}>
                    {
                        adminSidebar.sidebarAdminMenu.map((item, index) => {
                            return <AdminSidebarItem key={index} label={item.label} icon={item.icon} active={item.url?.includes(location.pathname as URLRoutes)} click={() => selectAdminMenuItem(item, index)} />
                        })
                    }
                </div>
            </div>
        </div>
    </div>
}

export default AdminSidebar;