// import { Button, Popover } from '@material-ui/core';
// import React, { useState } from 'react'
// import { useLocation } from 'react-router-dom';
// import MethodOfPayoutFilter from '../../../components/Filters/MethodOfPayoutFilter/MethodOfPayoutFilter';
import OptionsPayoutTable from '../../../components/Tables/OptionsPayoutTable/OptionsPayoutTable';
import { LocalStorage } from '../../../enums/LocalStorage';
import styles from './PayoutOptions.module.scss';
import AdminService from '../../../services/Admin';
import { ISnackbar } from '../../../interface/Snackbar';
import SnackbarAlert from '../../../components/SnackbarAlert/SnackbarAlert';
import { useEffect, useRef, useState } from 'react';
import { Modal } from '@material-ui/core';
import AddNewPayoutMethod from '../../../components/Tables/OptionsPayoutTable/AddNewPayoutMethod/AddNewPayoutMethod';
import EditPayoutMethodData from '../../../components/Tables/OptionsPayoutTable/EditPayoutMethodData/EditPayoutMethodData';

const PayoutOptions = () => {
    // const location = useLocation();
    // const queryString = require('query-string');
    // const tableUrlParams = ['page', 'order_by'];
    // const [filterDialog, setFilterDialog] = useState<HTMLButtonElement>();
    // const [filterParams, setFilterParams] = useState<any>(queryString.parse(location.search) ? Object.fromEntries(Object.entries(queryString.parse(location.search)).filter(([k, _]) => !tableUrlParams.includes(k))) : {});

    // const isEmptyObject = (object = {}) => {
    //     return Object.keys(object).length === 0;
    // }

    // const applyFilters = (parameters: any | undefined) => {
    //     setFilterParams(parameters);
    //     setFilterDialog(undefined);
    // }
    const [openModal, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [payoutMethodData, setPayoutMethodData] = useState<any>();
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });
    const [deleteButton, setDeleteButton] = useState(false);
    const childRef = useRef<any>();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const [openModalEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const saveNewPayoutOption = (option: any) => {
        setPayoutMethodData(null);
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        const parameters = {
            code: option.code,
            metode: option.paymentMethod
        }

        AdminService.addNewPayoutMethodAdmin(token, parameters).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'New Payout Option added.'
            })
            setOpen(false);
            childRef?.current.getRefresData();
            snackbarAutoHide();
        }).catch(error => {
            setSnackbar({
                open: true,
                severity: 'error',
                message: error && error.response && error.response.data ? error.response.data : 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }


    const editPayoutMethodData = (option: any) => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        const parameters = {
            SIFRA: option.SIFRA,
            NACIN_ISPLATE: option.NACIN_ISPLATE
        }

        AdminService.updatePayoutMethodAdmin(option.ID, token, parameters).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'New Payout Option edited.'
            })
            setOpenEdit(false);
            childRef?.current.getRefresData();
            snackbarAutoHide();
            handleCloseEdit();
        }).catch(error => {
            handleCloseEdit();
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const deletePayoutOption = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;

        setDeleteButton(selectedRows.length > 0 ? true : false);

        AdminService.deletePayoutMethodAdmin(selectedRows[0], token).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Payment Option deleted.'
            })

            childRef?.current.getRefresData();
            snackbarAutoHide();

        }).catch(error => {

            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const editOption = (option: any) => {
        setPayoutMethodData(option);
        handleOpenEdit();
    }


    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 5000);
    }

    useEffect(() => {
        setDeleteButton(selectedRows.length > 0 ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    return <div className={styles.admin_payout_options_wrapper}>
        <div className={styles.header_section}>
            <div className={styles.title}>Method Of Payout</div>
            <div className={styles.box_wrapper}>
                <div className={styles.title}>Add or Delete Method Of Payout</div>
                <div className={styles.subtitle}>Add a new payout method to be shown to users.</div>
                <div className={styles.button_section}>
                    <div><button onClick={handleOpen}>ADD NEW</button></div>
                    <div><button className={` ${!deleteButton ? styles.disabled : ''}`} onClick={deletePayoutOption}>DELETE</button></div>
                </div>
            </div>
        </div>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">
            <div><AddNewPayoutMethod close={() => handleClose()} saveNewPayoutMethodData={saveNewPayoutOption} /></div>
        </Modal>

        <Modal
            open={openModalEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">
            <div><EditPayoutMethodData close={() => handleCloseEdit()} payoutMethodData={payoutMethodData} editPayoutMethodData={editPayoutMethodData} /></div>
        </Modal>
        {/* <div className={styles.admin_payout_options_filter}>
            <Button className={`${styles.filter_circle} ${!isEmptyObject(filterParams) ? styles.active_filter : ''}`} onClick={(e) => setFilterDialog(e.currentTarget)}><span className="icon icon_filter"></span></Button>
            <Popover id='admin_payout_options_filter' open={Boolean(filterDialog)} anchorEl={filterDialog} onClose={() => setFilterDialog(undefined)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <MethodOfPayoutFilter appliedFilters={filterParams} applyFilters={applyFilters} close={() => setFilterDialog(undefined)} datasetFilter={true} />
            </Popover>
        </div> */}
        <OptionsPayoutTable editOption={editOption} ref={childRef} rowSelection={setSelectedRows} />
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>
}

export default PayoutOptions;