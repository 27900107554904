import React, { ChangeEvent, useState, useEffect } from 'react';
import styles from './AddNewPayoutMethod.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import { IFilterParameters } from '../../../../interface/TransferMoney';
import { Box, TextField } from '@material-ui/core';
import { KeyboardKeys } from '../../../../enums/Filters';

interface IPayoutMethod {
    close?: (event: any) => void,
    saveNewPayoutMethodData?: (parameters: any) => void
}

const AddNewPayoutMethod = (props: IPayoutMethod) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        borderRadius: 9,
        p: 4,
    };
    const [newPayoutMethodData, setNewPayoutMethodData] = useState<IFilterParameters>();
    const [applyButton, setApplyButton] = useState<boolean>(false);

    const [errorMsgCode, setErrorMsgCode] = useState<string | undefined>('');
    const [errorMsgPayoutMethod, setErrorMsgPayoutMethod] = useState<string | undefined>('');

    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputPayoutMethod, setErrorInputPayoutMethod] = useState(false);

    const handleChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name as keyof typeof newPayoutMethodData;


        if (name === 'code') {
            setErrorMsgCode(helperValidateOnlyNumbers(value))
            setErrorInputCode(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'paymentMethod') {
            setErrorMsgPayoutMethod(helperValidateNumberAndLetters(value))
            setErrorInputPayoutMethod(helperValidateNumberAndLetters(value) ? true : false);
        }


        setNewPayoutMethodData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setApplyButton(true);
    }

    const save = () => {
        if (props.saveNewPayoutMethodData) {
            props.saveNewPayoutMethodData(newPayoutMethodData);
            setApplyButton(false);
        }
    }

    const keyboardEvents = (event: { key: string; }) => {
        if (!applyButton) { return; }
        if (event.key === KeyboardKeys.Enter) {
            save();
        }
    }

    useEffect(() => {
        if (isEmptyPropertie(newPayoutMethodData?.code) && isEmptyPropertie(newPayoutMethodData?.paymentMethod)) {
            setApplyButton(false);
        } else if (!isEmptyPropertie(newPayoutMethodData?.code) && !isEmptyPropertie(newPayoutMethodData?.paymentMethod)) {
            setApplyButton(true);
        } else {
            setApplyButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPayoutMethodData]);

    const helperValidateOnlyNumbers = (input: string) => {
        if (input.replace(/^[0-9]+$/, '').length > 0) return 'Only numbers allowed!';
    }

    const helperValidateNumberAndLetters = (input: string) => {
        if (input.replace(/^[a-z0-9 ]+$/i, '').length > 0) return 'Only letters and numbers allowed!';
    }

    const isEmptyPropertie = (value: string | undefined) => {
        if (value === undefined) {
            return true;
        }
        return value.length === 0 || value === '' ? true : false;
    }


    return <Box sx={style}>
        <div tabIndex={0} className={styles.add_new_payout_method_container} onKeyPress={keyboardEvents}>
            <CloseIcon onClick={props.close} className={styles.close_icon} />
            <div className={styles.title_container}>
                <span className={`${styles.filter_icon} icon icon_options`}></span>
                <div>
                    <div className={styles.title}>Add a new payout method.</div>
                    <div className={styles.subtitle}>Add a new payout method to be shown to users.</div>
                </div>
            </div>
            <div className={styles.autocomplete_container}>
                <div>
                    <div className={styles.filter_label}>Code</div>
                    <TextField autoComplete='off' name='code' helperText={errorMsgCode} error={errorInputCode} className={styles.filter_field} placeholder={'Code'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 20 }} />
                </div>
                <div>
                    <div className={styles.filter_label}>Payout method</div>
                    <TextField autoComplete='off' name='paymentMethod' helperText={errorMsgPayoutMethod} error={errorInputPayoutMethod} className={styles.filter_field} placeholder={'Payment method'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 120 }} />
                </div>

            </div>

            <div className={styles.buttons_container}>
                <button className={`${styles.apply_button} ${!applyButton ? styles.disabled : ''}`} onClick={save}>{'SAVE'}</button>
            </div>
        </div>
    </Box>
}

export default AddNewPayoutMethod;