// import { Button, Popover } from '@material-ui/core';
// import React, { useState } from 'react'
// import { useLocation } from 'react-router-dom';
// import OptionsPaymentFilter from '../../../components/Filters/OptionsPaymentFilter/OptionsPaymentFilter';
import { Modal } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import AddNewPaymentMethod from '../../../components/Tables/OptionsPaymentTable/AddNewPaymentMethod/AddNewPaymentMethod';
import OptionsPaymentTable from '../../../components/Tables/OptionsPaymentTable/OptionsPaymentTable';
import { LocalStorage } from '../../../enums/LocalStorage';
import styles from './PaymentOptions.module.scss';
import AdminService from '../../../services/Admin';
import { ISnackbar } from '../../../interface/Snackbar';
import SnackbarAlert from '../../../components/SnackbarAlert/SnackbarAlert';
import EditPaymentMethodData from '../../../components/Tables/OptionsPaymentTable/EditPaymentMethodData/EditPaymentMethodData';

const PaymentOptions = () => {
    const [openModal, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const childRef = useRef<any>();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const [openModalEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);
    const [paymentMethodData, setPaymentMethodData] = useState<any>();
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });
    const [deleteButton, setDeleteButton] = useState(false);

    // const location = useLocation();
    // const queryString = require('query-string');
    // const tableUrlParams = ['page', 'order_by'];
    // const [filterDialog, setFilterDialog] = useState<HTMLButtonElement>();
    // const [filterParams, setFilterParams] = useState<any>(queryString.parse(location.search) ? Object.fromEntries(Object.entries(queryString.parse(location.search)).filter(([k, _]) => !tableUrlParams.includes(k))) : {});

    // const isEmptyObject = (object = {}) => {
    //     return Object.keys(object).length === 0;
    // }

    // const applyFilters = (parameters: any | undefined) => {
    //     setFilterParams(parameters);
    //     setFilterDialog(undefined);
    // }

    const saveNewPaymentOption = (option: any) => {
        setPaymentMethodData(null);
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        const parameters = {
            code: option.code,
            metode: option.paymentMethod
        }

        AdminService.addNewPaymentOption(token, parameters).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'New Payment Option added.'
            })
            setOpen(false);
            childRef?.current.getRefresData();
            snackbarAutoHide();
        }).catch(error => {
            setSnackbar({
                open: true,
                severity: 'error',
                message: error && error.response && error.response.data ? error.response.data : 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const editOption = (option: any) => {
        setPaymentMethodData(option);
        handleOpenEdit();
    }

    const editPaymentMethodData = (option: any) => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        const parameters = {
            SIFRA: option.SIFRA,
            NACIN_UPLATE: option.NACIN_UPLATE
        }

        AdminService.updatePaymentOption(option.ID, token, parameters).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Payment Option edited.'
            })
            setOpenEdit(false);
            childRef?.current.getRefresData();
            snackbarAutoHide();
            handleCloseEdit();
        }).catch(error => {
            handleCloseEdit();
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 5000);
    }

    const deletePaymentOption = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;

        setDeleteButton(selectedRows.length > 0 ? true : false);

        AdminService.deletePaymentOption(selectedRows[0], token).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Payment Option deleted.'
            })

            childRef?.current.getRefresData();
            snackbarAutoHide();

        }).catch(error => {

            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    useEffect(() => {
        setDeleteButton(selectedRows.length > 0 ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    return <div className={styles.admin_payment_options_wrapper}>
        <div className={styles.header_section}>
            <div className={styles.title}>Method Of Payment</div>
            <div className={styles.box_wrapper}>
                <div className={styles.title}>Add or Delete Method Of Payment</div>
                <div className={styles.subtitle}>Add a new payment method to be shown to users.</div>
                <div className={styles.button_section}>
                    <div><button onClick={handleOpen}>ADD NEW</button></div>
                    <div><button className={` ${!deleteButton ? styles.disabled : ''}`} onClick={deletePaymentOption}>DELETE</button></div>
                </div>
            </div>
        </div>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">
            <div><AddNewPaymentMethod close={() => handleClose()} saveNewPaymentMethodData={saveNewPaymentOption} /></div>
        </Modal>

        <Modal
            open={openModalEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">
            <div><EditPaymentMethodData close={() => handleCloseEdit()} paymentMethodData={paymentMethodData} editPaymentMethodData={editPaymentMethodData} /></div>
        </Modal>
        {/* <div className={styles.admin_payment_options_filter}>
            <Button className={`${styles.filter_circle} ${!isEmptyObject(filterParams) ? styles.active_filter : ''}`} onClick={(e) => setFilterDialog(e.currentTarget)}><span className="icon icon_filter"></span></Button>
            <Popover id='admin_payment_options_filter' open={Boolean(filterDialog)} anchorEl={filterDialog} onClose={() => setFilterDialog(undefined)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <OptionsPaymentFilter appliedFilters={filterParams} applyFilters={applyFilters} close={() => setFilterDialog(undefined)} datasetFilter={true} />
            </Popover>
        </div> */}
        <OptionsPaymentTable editOption={editOption} ref={childRef} rowSelection={setSelectedRows} />
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>
}

export default PaymentOptions;