import React, { ChangeEvent, useState, useEffect } from 'react';
import styles from './AddCommissionFee.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import { IFilterParameters } from '../../../../interface/TransferMoney';
import { Box, TextField } from '@material-ui/core';
import { KeyboardKeys } from '../../../../enums/Filters';

interface ICommissionFee {
    close?: (event: any) => void,
    saveNewCommissionFeeData?: (parameters: any) => void
}

const AddCommissionFee = (props: ICommissionFee) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        borderRadius: 9,
        p: 4,
    };
    const [newCommissionFee, setNewCommissionFee] = useState<IFilterParameters>();
    const [applyButton, setApplyButton] = useState<boolean>(false);

    const [errorMsgCode, setErrorMsgCode] = useState<string | undefined>('');
    const [errorMsgAmountOf, setErrorMsgAmountOf] = useState<string | undefined>('');
    const [errorMsgAmountTo, setErrorMsgAmountTo] = useState<string | undefined>('');
    const [errorMsgAmount, setErrorMsgAmount] = useState<string | undefined>('');
    const [errorMsgPercentage, setErrorMsgPercentage] = useState<string | undefined>('');

    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputAmountOf, setErrorInputAmountOf] = useState(false);
    const [errorInputAmountTo, setErrorInputAmountTo] = useState(false);
    const [errorInputAmount, setErrorInputAmount] = useState(false);
    const [errorInputPercentage, setErrorInputPercentage] = useState(false);


    const handleChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name as keyof typeof newCommissionFee;


        if (name === 'code') {
            setErrorMsgCode(helperValidateOnlyNumbers(value))
            setErrorInputCode(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'amountOf') {
            setErrorMsgAmountOf(helperValidateOnlyNumbers(value))
            setErrorInputAmountOf(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'amountTo') {
            setErrorMsgAmountTo(helperValidateOnlyNumbers(value))
            setErrorInputAmountTo(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'amount') {
            setErrorMsgAmount(helperValidateOnlyNumbers(value))
            setErrorInputAmount(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'percentage') {
            setErrorMsgPercentage(helperValidateOnlyNumbers(value))
            setErrorInputPercentage(helperValidateOnlyNumbers(value) ? true : false);
        }


        setNewCommissionFee(prevState => ({
            ...prevState,
            [name]: value
        }));

        setApplyButton(true);
    }

    const save = () => {
        if (props.saveNewCommissionFeeData) {
            props.saveNewCommissionFeeData(newCommissionFee);
            setApplyButton(false);
        }
    }

    const keyboardEvents = (event: { key: string; }) => {
        if (!applyButton) { return; }
        if (event.key === KeyboardKeys.Enter) {
            save();
        }
    }

    useEffect(() => {
        if (isEmptyPropertie(newCommissionFee?.code) && isEmptyPropertie(newCommissionFee?.amountOf) && isEmptyPropertie(newCommissionFee?.amountTo) && isEmptyPropertie(newCommissionFee?.amount) && isEmptyPropertie(newCommissionFee?.percentage)) {
            setApplyButton(false);
        } else if (!isEmptyPropertie(newCommissionFee?.code) && !isEmptyPropertie(newCommissionFee?.amountOf) && !isEmptyPropertie(newCommissionFee?.amountTo) && !isEmptyPropertie(newCommissionFee?.amount) && !isEmptyPropertie(newCommissionFee?.percentage)) {
            setApplyButton(true);
        } else {
            setApplyButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newCommissionFee]);

    const helperValidateOnlyNumbers = (input: string) => {
        if (input.replace(/^\d*\.?\d*$/, '').length > 0) return 'Only numbers allowed!';
    }

    const isEmptyPropertie = (value: string | undefined) => {
        if (value === undefined) {
            return true;
        }
        return value.length === 0 || value === '' ? true : false;
    }


    return <Box sx={style}>
        <div tabIndex={0} className={styles.add_new_commision_fee_container} onKeyPress={keyboardEvents}>
            <CloseIcon onClick={props.close} className={styles.close_icon} />
            <div className={styles.title_container}>
                <span className={`${styles.filter_icon} icon icon_options`}></span>
                <div>
                    <div className={styles.title}>Add a new commission fee.</div>
                    <div className={styles.subtitle}>Add a new commision fee to be used in calculator.</div>
                </div>
            </div>
            <div className={styles.autocomplete_container}>
                <div>
                    <div className={styles.filter_label}>Code</div>
                    <TextField autoComplete='off' name='code' helperText={errorMsgCode} error={errorInputCode} className={styles.filter_field} placeholder={'Code'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 20 }} />
                </div>
                <div>
                    <div className={styles.filter_label}>Amount from</div>
                    <TextField autoComplete='off' name='amountOf' helperText={errorMsgAmountOf} error={errorInputAmountOf} className={styles.filter_field} placeholder={'Amount from'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 10 }} />
                </div>
                <div>
                    <div className={styles.filter_label}>Amount to</div>
                    <TextField autoComplete='off' name='amountTo' helperText={errorMsgAmountTo} error={errorInputAmountTo} className={styles.filter_field} placeholder={'Amount to'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 10 }} />
                </div>
            </div>

            <div className={styles.autocomplete_container}>
                <div>
                    <div className={styles.filter_label}>Amount</div>
                    <TextField autoComplete='off' name='amount' helperText={errorMsgAmount} error={errorInputAmount} className={styles.filter_field} placeholder={'Amount'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 10 }} />
                </div>
                <div>
                    <div className={styles.filter_label}>Percentage</div>
                    <TextField autoComplete='off' name='percentage' helperText={errorMsgPercentage} error={errorInputPercentage} className={styles.filter_field} placeholder={'Percentage'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 5 }} />
                </div>
            </div>

            <div className={styles.buttons_container}>
                <button className={`${styles.apply_button} ${!applyButton ? styles.disabled : ''}`} onClick={save}>{'SAVE'}</button>
            </div>
        </div>
    </Box>
}

export default AddCommissionFee;