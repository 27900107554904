import React, { ChangeEvent, useState, useEffect } from 'react';
import styles from './EditExchangeRate.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import { IFilterParameters } from '../../../../interface/TransferMoney';
import { Box, TextField } from '@material-ui/core';
import { KeyboardKeys } from '../../../../enums/Filters';
import { formatDate } from '../../../../helpers/DateTimeHelper';
import moment from 'moment';
import MaskedInput from 'react-maskedinput';

interface IPaymentMethod {
    close?: (event: any) => void,
    editExchangeRate?: (parameters: any) => void,
    ExchangeRateData?: any
}

const EditExchangeRate = (props: IPaymentMethod) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        borderRadius: 9,
        p: 4,
    };
    const [editExchangeRate, setEditExchangeRate] = useState<{ ID: string, KURS: number, DATUM: string }>(props.ExchangeRateData);
    const [applyButton, setApplyButton] = useState<boolean>(false);

    const [errorMsgCurrency, setErrorMsgCurrency] = useState<string | undefined>('');
    const [errorMsgCurrencyDate, setErrorMsgCurrencyDate] = useState<string | undefined>('');

    const [errorInputCurrency, setErrorInputCurrency] = useState(false);
    const [errorInputCurrencyDate, setErrorInputCurrencyDate] = useState(false);

    const handleChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name as keyof typeof editExchangeRate;

        if (name === 'KURS') {
            setErrorMsgCurrency(helperValidateOnlyNumbers(value))
            setErrorInputCurrency(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'DATUM') {
            for (let index = 0; index < value.length; index++) {
                if (value[index] !== '_' && index === 9) {
                    const date = compareDate(value);
                    value = moment(date).format('YYYY-MM-DD');
                }
            }
        }

        setEditExchangeRate(prevState => ({
            ...prevState,
            [name]: value
        }));

        setApplyButton(true);
    }

    useEffect(() => {
        if (props.ExchangeRateData && !isEmptyObject(props.ExchangeRateData)) {
            setEditExchangeRate(props.ExchangeRateData);
        }

    }, [props.ExchangeRateData]);

    const isEmptyObject = (object?: IFilterParameters) => {
        if (object) {
            Object.keys(object).forEach(key => object[key as keyof object] === '' ? delete object[key as keyof object] : {});
            return Object.values(object).length === 0;
        }

    }

    const edit = () => {
        if (props.editExchangeRate) {
            props.editExchangeRate(editExchangeRate);
            setApplyButton(false);
        }
    }

    const keyboardEvents = (event: { key: string; }) => {
        if (!applyButton) { return; }
        if (event.key === KeyboardKeys.Enter) {
            edit();
        }
    }

    const compareDate = (str: string) => {
        // str1 format should be dd/mm/yyyy. Separator can be anything e.g. / or -. It wont effect
        const dt1 = parseInt(str.substring(0, 2));
        const mon1 = parseInt(str.substring(3, 5));
        const yr1 = parseInt(str.substring(6, 10));
        const date1 = new Date(yr1, mon1 - 1, dt1);
        return date1;
    }

    useEffect(() => {
        if (isEmptyPropertie(String(editExchangeRate?.KURS)) && isEmptyPropertie(String(editExchangeRate?.DATUM))) {
            setApplyButton(false);
        } else if (!isEmptyPropertie(String(editExchangeRate?.KURS)) && !isEmptyPropertie(String(editExchangeRate?.DATUM))) {
            setApplyButton(true);
        } else {
            setApplyButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editExchangeRate]);

    const helperValidateOnlyNumbers = (input: string) => {
        if (input.replace(/^\d*\.?\d*$/, '').length > 0) return 'Only numbers allowed!';
    }

    const isEmptyPropertie = (value: string | undefined) => {
        if (value === undefined) {
            return true;
        }
        return value.length === 0 || value === '' ? true : false;
    }

    return <Box sx={style}>
        <div tabIndex={0} className={styles.edit_payment_method_container} onKeyPress={keyboardEvents}>
            <CloseIcon onClick={props.close} className={styles.close_icon} />
            <div className={styles.title_container}>
                <span className={`${styles.filter_icon} icon icon_options`}></span>
                <div>
                    <div className={styles.title}>Edit exchange rate.</div>
                    <div className={styles.subtitle}>Edit existing exchange rate.</div>
                </div>
            </div>
            <div className={styles.autocomplete_container}>
                <div>
                    <div className={styles.filter_label}>Currency</div>
                    <TextField autoComplete='off' name='KURS' value={editExchangeRate.KURS || ''} helperText={errorMsgCurrency} error={errorInputCurrency} className={styles.filter_field} placeholder={'Currency'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} />
                </div>
                <div>
                    <div className={styles.filter_label}>Currency Date</div>
                    {/* <TextField autoComplete='off' name='currencyDate' value={formatDate(editExchangeRate.DATUM) || ''} className={styles.filter_field} placeholder={'Currency Date'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} /> */}
                    <MaskedInput mask="11/11/1111" value={formatDate(editExchangeRate.DATUM) || ''} className={styles.input_field_masked} name="DATUM" placeholder="dd/mm/yyyy" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} />
                    <div className={styles.error_msg_date}>{errorInputCurrencyDate ? errorMsgCurrencyDate : ''}</div>
                </div>
            </div>

            <div className={styles.buttons_container}>
                <button className={`${styles.apply_button} ${!applyButton ? styles.disabled : ''}`} onClick={edit}>{'SAVE'}</button>
            </div>
        </div>
    </Box>
}

export default EditExchangeRate;