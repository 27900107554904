import React, { ChangeEvent, useState, useEffect } from 'react';
import styles from './EditPayoutMethodData.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import { IFilterParameters } from '../../../../interface/TransferMoney';
import { Box, makeStyles, TextField } from '@material-ui/core';
import { KeyboardKeys } from '../../../../enums/Filters';

interface IPaymentMethod {
    close?: (event: any) => void,
    editPayoutMethodData?: (parameters: any) => void,
    payoutMethodData?: any
}

const EditPayoutMethodData = (props: IPaymentMethod) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        borderRadius: 9,
        p: 4,
    };
    const useStyles = makeStyles((theme) => ({
        inputRoot: {
            "&$disabled": {
                backgroundColor: "gray"
            }
        },
        disabled: {}
    }));
    const classes = useStyles();
    const [editPayoutMethodData, setEditPayoutMethodData] = useState<{ ID: string, SIFRA: string, NACIN_ISPLATE: string }>(props.payoutMethodData);
    const [applyButton, setApplyButton] = useState<boolean>(false);

    const [errorMsgCode, setErrorMsgCode] = useState<string | undefined>('');
    const [errorMsgPayoutMethod, setErrorMsgPayoutMethod] = useState<string | undefined>('');

    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputPayoutMethod, setErrorInputPayoutMethod] = useState(false);

    const handleChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name as keyof typeof editPayoutMethodData;

        if (name === 'SIFRA') {
            setErrorMsgCode(helperValidateOnlyNumbers(value))
            setErrorInputCode(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'NACIN_ISPLATE') {
            //setErrorMsgPayoutMethod(helperValidateNumberAndLetters(value))
            // setErrorInputPayoutMethod(helperValidateNumberAndLetters(value) ? true : false);
        }

        setEditPayoutMethodData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setApplyButton(true);
    }

    useEffect(() => {
        if (props.payoutMethodData && !isEmptyObject(props.payoutMethodData)) {
            setEditPayoutMethodData(props.payoutMethodData);
        }

    }, [props.payoutMethodData]);

    const isEmptyObject = (object?: IFilterParameters) => {
        if (object) {
            Object.keys(object).forEach(key => object[key as keyof object] === '' ? delete object[key as keyof object] : {});
            return Object.values(object).length === 0;
        }

    }

    const edit = () => {
        if (props.editPayoutMethodData) {
            props.editPayoutMethodData(editPayoutMethodData);
            setApplyButton(false);
        }
    }

    const keyboardEvents = (event: { key: string; }) => {
        if (!applyButton) { return; }
        if (event.key === KeyboardKeys.Enter) {
            edit();
        }
    }

    useEffect(() => {
        if (isEmptyPropertie(editPayoutMethodData?.SIFRA) && isEmptyPropertie(editPayoutMethodData?.NACIN_ISPLATE)) {
            setApplyButton(false);
        } else if (!isEmptyPropertie(editPayoutMethodData?.SIFRA) && !isEmptyPropertie(editPayoutMethodData?.NACIN_ISPLATE)) {
            setApplyButton(true);
        } else {
            setApplyButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editPayoutMethodData]);

    const helperValidateOnlyNumbers = (input: string) => {
        if (input.replace(/^[0-9]+$/, '').length > 0) return 'Only numbers allowed!';
    }

    const helperValidateNumberAndLetters = (input: string) => {
        if (input.replace(/^[a-z0-9 ]+$/i, '').length > 0) return 'Only letters and numbers allowed!';
    }

    const isEmptyPropertie = (value: string | undefined) => {
        if (value === undefined) {
            return true;
        }
        return value.length === 0 || value === '' ? true : false;
    }

    return <Box sx={style}>
        <div tabIndex={0} className={styles.edit_payment_method_container} onKeyPress={keyboardEvents}>
            <CloseIcon onClick={props.close} className={styles.close_icon} />
            <div className={styles.title_container}>
                <span className={`${styles.filter_icon} icon icon_options`}></span>
                <div>
                    <div className={styles.title}>Edit payout.</div>
                    <div className={styles.subtitle}>Edit existing method of payout.</div>
                </div>
            </div>
            <div className={styles.autocomplete_container}>
                <div>
                    <div className={styles.filter_label}>Code</div>
                    <TextField autoComplete='off' name='SIFRA' InputProps={{ classes: { disabled: classes.disabled } }} disabled={true} value={editPayoutMethodData.SIFRA || ''} className={styles.filter_field} helperText={errorMsgCode} error={errorInputCode} placeholder={'Code'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 20 }} />
                </div>
                <div>
                    <div className={styles.filter_label}>Payout method</div>
                    <TextField autoComplete='off' name='NACIN_ISPLATE' value={editPayoutMethodData.NACIN_ISPLATE || ''} className={styles.filter_field} helperText={errorMsgPayoutMethod} error={errorInputPayoutMethod} placeholder={'Payout method'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 120 }} />
                </div>

            </div>

            <div className={styles.buttons_container}>
                <button className={`${styles.apply_button} ${!applyButton ? styles.disabled : ''}`} onClick={edit}>{'SAVE'}</button>
            </div>
        </div>
    </Box>
}

export default EditPayoutMethodData;