export enum PageSizeParameters {
    PageSizeTen = 10
}

export enum DocymentType {
    None = 'None',
    DriversLicence = 'Drivers Licence',
    Passport = 'Passport',
    PhotoID = 'Photo ID',
    Other = 'Other'
}

export enum Country {
    None = 'None',
    Australia = 'Australia',
}

export enum States {
    None = 'None',
    NewSouthWales = 'New South Wales',
    SouthAustralia = 'South Australia',
    Victoria = 'Victoria',
    Queensland = 'Queensland',
    Tasmania = 'Tasmania',
    WesternAustralia = 'Western Australia'
}

export enum PaymentMethod {
    Online = 'Online – Transfer to Bendigo Bank'
}

export enum ReasonForTransfer {
    FamilyAssistance = 'Family assistance',
    BillPayment = 'Bill Payment',
    LoanRepayment = 'Loan Repayment',
    RealEstateInvestment = 'Real Estate Investment',
    Other = 'Other'
}

export enum PayoutMethod {
    OverTheCounterCASH = 'Over the Counter - CASH',
    AccountDepositLocalCurrency = 'Account Deposit - Local Currency',
    AccountDepositEUR = 'Account Deposit - EUR'
}


export enum BalkanCountry {
    SERBIA = 2,
    MONTENEGRO = 3,
    BOSNIAHERZEGOVINA = 4,
    CROATIA = 5,
    MACEDONIA = 18,
    SLOVENIA = 17
}