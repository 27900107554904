// import { Button, Popover } from '@material-ui/core';
// import React, { useState } from 'react'
// import { useLocation } from 'react-router-dom';
// import PurposeOfTransferFilter from '../../../components/Filters/PurposeOfTransferFilter/PurposeOfTransferFilter';
import { useEffect, useRef, useState } from 'react';
import PurposeOfTransferTable from '../../../components/Tables/PurposeOfTransferTable/PurposeOfTransferTable';
import { LocalStorage } from '../../../enums/LocalStorage';
import { ISnackbar } from '../../../interface/Snackbar';
import styles from './PurposeOfTransfer.module.scss';
import AdminService from '../../../services/Admin';
import { Modal } from '@material-ui/core';
import AddPurposeOfTransfer from '../../../components/Tables/PurposeOfTransferTable/AddPurposeOfTransfer/AddPurposeOfTransfer';
import EditPurposeOfTransfer from '../../../components/Tables/PurposeOfTransferTable/EditPurposeOfTransfer/EditPurposeOfTransfer';
import SnackbarAlert from '../../../components/SnackbarAlert/SnackbarAlert';

const PurposeOfTransfer = () => {
    // const location = useLocation();
    // const queryString = require('query-string');
    // const tableUrlParams = ['page', 'order_by'];
    // const [filterDialog, setFilterDialog] = useState<HTMLButtonElement>();
    // const [filterParams, setFilterParams] = useState<any>(queryString.parse(location.search) ? Object.fromEntries(Object.entries(queryString.parse(location.search)).filter(([k, _]) => !tableUrlParams.includes(k))) : {});

    // const isEmptyObject = (object = {}) => {
    //     return Object.keys(object).length === 0;
    // }

    // const applyFilters = (parameters: any | undefined) => {
    //     setFilterParams(parameters);
    //     setFilterDialog(undefined);
    // }
    const [openModal, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [payoutMethodData, setPayoutMethodData] = useState<any>();
    const [snackbar, setSnackbar] = useState<ISnackbar>({ open: false, severity: 'success', message: '' });
    const [deleteButton, setDeleteButton] = useState(false);
    const childRef = useRef<any>();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const [openModalEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    const savePurposeOfTransfer = (option: any) => {
        setPayoutMethodData(null);
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        const parameters = {
            code: option.code,
            purpose_of_transfer: option.purposeOfTransfer
        }

        AdminService.addNewPurposeOfTransfer(token, parameters).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'New Purpose Of Transfer added.'
            })
            setOpen(false);
            childRef?.current.getRefresData();
            snackbarAutoHide();
        }).catch(error => {
            setSnackbar({
                open: true,
                severity: 'error',
                message: error && error.response && error.response.data ? error.response.data : 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }


    const editPurposeOfTransfer = (option: any) => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null
        const parameters = {
            SIFRA: option.SIFRA,
            SVRHA_TRANSFERA: option.SVRHA_TRANSFERA
        }

        AdminService.updatePurposeOfTransfer(option.ID, token, parameters).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'New Purpose Of Transfer edited.'
            })
            setOpenEdit(false);
            childRef?.current.getRefresData();
            snackbarAutoHide();
            handleCloseEdit();
        }).catch(error => {
            handleCloseEdit();
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const deletePurposeOfTransfer = () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;

        setDeleteButton(selectedRows.length > 0 ? true : false);

        AdminService.deletePurposeOfTransfer(selectedRows[0], token).then(response => {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Purpose Of Transfer deleted.'
            })

            childRef?.current.getRefresData();
            snackbarAutoHide();

        }).catch(error => {

            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Something went wrong.'
            })
            snackbarAutoHide();
        });
    }

    const editOption = (option: any) => {
        setPayoutMethodData(option);
        handleOpenEdit();
    }


    const snackbarAutoHide = () => {
        setTimeout(() => {
            setSnackbar({ open: false });
        }, 5000);
    }

    useEffect(() => {
        setDeleteButton(selectedRows.length > 0 ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);

    return <div className={styles.admin_purpose_transfer_wrapper}>
        <div className={styles.header_section}>
            <div className={styles.title}>Purpose Of Transfer</div>
            <div className={styles.box_wrapper}>
                <div className={styles.title}>Purpose Of Transfer</div>
                <div className={styles.subtitle}>Add a new purpose of transfer to be shown to users.</div>
                <div className={styles.button_section}>
                    <div><button onClick={handleOpen}>ADD NEW</button></div>
                    <div><button className={` ${!deleteButton ? styles.disabled : ''}`} onClick={deletePurposeOfTransfer}>DELETE</button></div>
                </div>
            </div>
        </div>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">
            <div><AddPurposeOfTransfer close={() => handleClose()} saveNewPurposeOfTransferData={savePurposeOfTransfer} /></div>
        </Modal>

        <Modal
            open={openModalEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-profile-title"
            aria-describedby="modal-profile-description">
            <div><EditPurposeOfTransfer close={() => handleCloseEdit()} PurposeOfTransferData={payoutMethodData} editPurposeOfTransferData={editPurposeOfTransfer} /></div>
        </Modal>
        {/* <div className={styles.admin_purpose_transfer_filter}>
            <Button className={`${styles.filter_circle} ${!isEmptyObject(filterParams) ? styles.active_filter : ''}`} onClick={(e) => setFilterDialog(e.currentTarget)}><span className="icon icon_filter"></span></Button>
            <Popover id='admin_purpose_transfer_filter' open={Boolean(filterDialog)} anchorEl={filterDialog} onClose={() => setFilterDialog(undefined)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <PurposeOfTransferFilter appliedFilters={filterParams} applyFilters={applyFilters} close={() => setFilterDialog(undefined)} datasetFilter={true} />
            </Popover>
        </div> */}
        <PurposeOfTransferTable editOption={editOption} ref={childRef} rowSelection={setSelectedRows} />
        <SnackbarAlert open={snackbar.open} severity={snackbar.severity} message={snackbar.message} />
    </div>
}

export default PurposeOfTransfer;