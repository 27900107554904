import styles from './BeoExportTableContainer.module.scss'
import { TableContainer, TableProps } from '@material-ui/core';
import React from 'react';

const BeoExportTableContainer = (props: TableProps) => {

    return <TableContainer className={styles.be_table_container}>
        {props.children}
    </TableContainer>
}

export default BeoExportTableContainer;