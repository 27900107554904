import React, { ReactNode, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AboutUs from "../../containers/AboutUs/AboutUs";
import Profile from "../../containers/Profile/Profile";
import TransactionListing from "../../containers/TransactionList/TransactionListing";
import MoneyTransfer from "../../containers/TransferMoney/MoneyTransfer";
import { URLRoutes } from "../../enums/Routes";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import styles from './Layout.module.scss'
import { slide as Menu } from 'react-burger-menu'
import AdminSidebar from "../AdminSidebar/AdminSidebar";
import { useIdleTimer } from 'react-idle-timer'
import { StepContext } from "../StepContext/StepContext";
import TransactionViewClient from "../../containers/TransactionList/TransactionView/TransactionView";
import Auth from "../../services/Auth";

interface ILayout {
    children: ReactNode
}

const Layout = (props: ILayout) => {
    let history = useHistory();
    const [currentStep, setCurrentStep] = useState<number>();
    const location = useLocation();
    const [desktopWidth, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    const [burgerMenu, setBurgerMenu] = useState(false);
    const [visibiltyAdminMenu, setVisibiltyAdminMenu] = useState<boolean>(false);

    useEffect(() => {
        if (location.pathname !== URLRoutes.MoneyTransfer) {
            setCurrentStep(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, location.pathname]);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        setBurgerMenu(desktopWidth < 640 ? true : false);
    }, [desktopWidth, height]);

    useEffect(() => {
        let adminLevelString = localStorage.getItem('auth');
        let adminLevel = adminLevelString && adminLevelString.length > 0 ? JSON.parse(adminLevelString) : '';
        setVisibiltyAdminMenu(Number(adminLevel && adminLevel.ov ? adminLevel.ov : 0) === 3 ? true : false);
    }, [location.pathname]);

    const handleOnIdle = () => {
        localStorage.removeItem('auth');
        history.push(URLRoutes.Login);
    }

    useIdleTimer({
        timeout: 3600000,
        onIdle: handleOnIdle,
        debounce: 500
    })

    return (burgerMenu ?
        <div className={styles.layout_wrapper}>
            <StepContext.Provider value={{ currentStep, setCurrentStep }}>
                <Menu>
                    <a id="home" className="menu-item" href={URLRoutes.MoneyTransfer}>Home</a>
                    <a id="transfer" className="menu-item" href={URLRoutes.MoneyTransfer}>Money Transfer</a>
                    <a id="transfer" className="menu-item" href={URLRoutes.TransactionListing}>Transaction listing</a>
                    <a id="profile" className="menu-item" href={URLRoutes.Profile}>Profile</a>
                    <a id="contact" className="menu-item" href={URLRoutes.AboutUs}>About us</a>
                    <a id="logout" className="menu-item" href="#" onClick={() => Auth.logout()}>Logout</a>
                </Menu>
                <div className={`${styles.layout_content}`}>
                    {location.pathname === URLRoutes.MoneyTransfer ? <MoneyTransfer /> : null}
                    {location.pathname === URLRoutes.TransactionListing ? <TransactionListing /> : null}
                    {location.pathname === URLRoutes.TransactionItem ? <TransactionViewClient /> : null}
                    {location.pathname === URLRoutes.AboutUs ? <AboutUs /> : null}
                    {location.pathname === URLRoutes.Profile ? <Profile /> : null}
                </div>
            </StepContext.Provider>
        </div> :
        <div className={styles.layout_wrapper}>
            <Header />
            <StepContext.Provider value={{ currentStep, setCurrentStep }}>
                {
                    visibiltyAdminMenu ? <AdminSidebar /> : <Sidebar currentStep={currentStep} />
                }
                <div className={`${styles.layout_content}`}>
                    {props.children}
                </div>
            </StepContext.Provider>
        </div>
    );
}

export default Layout;