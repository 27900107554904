import axios from './config/axios';

class AdminService {

    getOptionsPayment = (token: string, page: number, orderBy: string, sortBy: string) => {
        return axios({
            method: 'get',
            url: `/methodOfPayment/data/method-of-payment/all/${page}/${orderBy}/${sortBy}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getExchangeRate = (token: string, page: number, orderBy: string, sortBy: string) => {
        return axios({
            method: 'get',
            url: `/exchangeRate/data/all-exchange-rate/${page}/${orderBy}/${sortBy}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getCommissionFee = (token: string, page: number, orderBy: string, sortBy: string) => {
        return axios({
            method: 'get',
            url: `/commission/data/all/commission/fee/${page}/${orderBy}/${sortBy}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    getPayoutMethodAdmin = (token: string, page: number, orderBy: string, sortBy: string) => {
        return axios({
            method: 'get',
            url: `/methodOfPayout/data/method-of-payout/all/${page}/${orderBy}/${sortBy}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getPurposeOfTransfer = (token: string, page: number, orderBy: string, sortBy: string) => {
        return axios({
            method: 'get',
            url: `/purposeOfTransfer/data/purpose-of-transfer/all/${page}/${orderBy}/${sortBy}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getAllUser = (token: string, page: number, orderBy: string, sortBy: string, filter: any) => {
        return axios({
            method: 'post',
            data: filter,
            url: `/users/data/all-users/${page}/${orderBy}/${sortBy}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    getAllAdmins = (token: string, page: number, orderBy: string, sortBy: string, filter: any) => {
        return axios({
            method: 'post',
            data: filter,
            url: `/users/data/all-admin-users/${page}/${orderBy}/${sortBy}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    getAllAdminTransactions = (token: string, page: number, orderBy: string, sortBy: string, filter: any) => {
        return axios({
            method: 'post',
            data: filter,
            url: `/adminTransactionList/data/all-admin-user-transaction/${page}/${orderBy}/${sortBy}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    addNewPaymentOption = (token: string, data: any) => {
        return axios({
            method: 'post',
            url: `methodOfPayment/data/method-of-payment/add`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    updatePaymentOption = (id: string, token: string, data: any) => {
        return axios({
            method: 'put',
            url: `methodOfPayment/data/method-of-payment/edit/${id}`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    deletePaymentOption = (id: string, token: string) => {
        return axios({
            method: 'delete',
            url: `methodOfPayment/data/method-of-payment/delete/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    addNewPayoutMethodAdmin = (token: string, data: any) => {
        return axios({
            method: 'post',
            url: `/methodOfPayout/data/method-of-payout/add`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    updatePayoutMethodAdmin = (id: string, token: string, data: any) => {
        return axios({
            method: 'put',
            url: `methodOfPayout/data/method-of-payout/edit/${id}`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    deletePayoutMethodAdmin = (id: string, token: string) => {
        return axios({
            method: 'delete',
            url: `methodOfPayout/data/method-of-payout/delete/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    addNewPurposeOfTransfer = (token: string, data: any) => {
        return axios({
            method: 'post',
            url: `/purposeOfTransfer/data/purpose-of-transfer/add`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    updatePurposeOfTransfer = (id: string, token: string, data: any) => {
        return axios({
            method: 'put',
            url: `purposeOfTransfer/data/purpose-of-transfer/edit/${id}`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    deletePurposeOfTransfer = (id: string, token: string) => {
        return axios({
            method: 'delete',
            url: `purposeOfTransfer/data/purpose-of-transfer/delete/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    addNewExchangeRate = (token: string, data: any) => {
        return axios({
            method: 'post',
            url: `/exchangeRate/data/exchange-rate/add`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    updateExchangeRate = (id: string, token: string, data: any) => {
        return axios({
            method: 'put',
            url: `exchangeRate/data/exchange-rate/edit/${id}`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    addNewCommissionFee = (token: string, data: any) => {
        return axios({
            method: 'post',
            url: `/commission/data/commission/fee/add`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    updateCommissionFee = (id: string, token: string, data: any) => {
        return axios({
            method: 'put',
            url: `commission/data/commission/fee/edit/${id}`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    deleteCommissionFee = (id: string, token: string) => {
        return axios({
            method: 'delete',
            url: `commission/data/commission/fee/delete/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    deleteTransaction = (id: string, token: string) => {
        return axios({
            method: 'delete',
            url: `adminTransactionList/data/all-admin-user-transaction/delete/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    deleteUser = (id: string, token: string) => {
        return axios({
            method: 'delete',
            url: `users/data/delete/${id}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    updateUserAdminSide = (id: string, token: string, data: any) => {
        return axios({
            method: 'put',
            url: `users/data/all-users/edit/${id}`,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

}

export default new AdminService();