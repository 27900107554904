import { URLRoutes } from '../enums/Routes';
import { LocalStorage } from '../enums/LocalStorage';

class AuthService {

    logout = () => {
        localStorage.removeItem(LocalStorage.Auth);
        window.location.href = URLRoutes.Login;
    }
}
export default new AuthService();
