import React, { ChangeEvent, useState, useEffect } from 'react';
import styles from './EditCommissionFee.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import { IFilterParameters } from '../../../../interface/TransferMoney';
import { Box, makeStyles, TextField } from '@material-ui/core';
import { KeyboardKeys } from '../../../../enums/Filters';

interface ICommissionFee {
    close?: (event: any) => void,
    editCommissionFeeData?: (parameters: any) => void,
    CommissionFeeData?: any
}

const EditCommissionFee = (props: ICommissionFee) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        borderRadius: 9,
        p: 4,
    };
    const useStyles = makeStyles((theme) => ({
        inputRoot: {
            "&$disabled": {
                backgroundColor: "gray"
            }
        },
        disabled: {}
    }));
    const classes = useStyles();
    const [editCommissionFee, setEditCommissionFee] = useState<{ ID: string, SIFRA: string, IZNOSOD: string, IZNOSDO: string, IZNOS: string, PROCENAT: string, }>(props.CommissionFeeData);
    const [applyButton, setApplyButton] = useState<boolean>(false);

    const [errorMsgCode, setErrorMsgCode] = useState<string | undefined>('');
    const [errorMsgAmountOf, setErrorMsgAmountOf] = useState<string | undefined>('');
    const [errorMsgAmountTo, setErrorMsgAmountTo] = useState<string | undefined>('');
    const [errorMsgAmount, setErrorMsgAmount] = useState<string | undefined>('');
    const [errorMsgPercentage, setErrorMsgPercentage] = useState<string | undefined>('');

    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputAmountOf, setErrorInputAmountOf] = useState(false);
    const [errorInputAmountTo, setErrorInputAmountTo] = useState(false);
    const [errorInputAmount, setErrorInputAmount] = useState(false);
    const [errorInputPercentage, setErrorInputPercentage] = useState(false);

    const handleChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name as keyof typeof editCommissionFee;


        if (name === 'SIFRA') {
            setErrorMsgCode(helperValidateOnlyNumbers(value))
            setErrorInputCode(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'IZNOSOD') {
            setErrorMsgAmountOf(helperValidateOnlyNumbers(value))
            setErrorInputAmountOf(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'IZNOSDO') {
            setErrorMsgAmountTo(helperValidateOnlyNumbers(value))
            setErrorInputAmountTo(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'IZNOS') {
            setErrorMsgAmount(helperValidateOnlyNumbers(value))
            setErrorInputAmount(helperValidateOnlyNumbers(value) ? true : false);
        }

        if (name === 'PROCENAT') {
            setErrorMsgPercentage(helperValidateOnlyNumbers(value))
            setErrorInputPercentage(helperValidateOnlyNumbers(value) ? true : false);
        }

        setEditCommissionFee(prevState => ({
            ...prevState,
            [name]: value
        }));

        setApplyButton(true);
    }

    useEffect(() => {
        if (props.CommissionFeeData && !isEmptyObject(props.CommissionFeeData)) {
            setEditCommissionFee(props.CommissionFeeData);
        }

    }, [props.CommissionFeeData]);

    const isEmptyObject = (object?: IFilterParameters) => {
        if (object) {
            Object.keys(object).forEach(key => object[key as keyof object] === '' ? delete object[key as keyof object] : {});
            return Object.values(object).length === 0;
        }

    }

    const edit = () => {
        if (props.editCommissionFeeData) {
            props.editCommissionFeeData(editCommissionFee);
            setApplyButton(false);
        }
    }

    const keyboardEvents = (event: { key: string; }) => {
        if (!applyButton) { return; }
        if (event.key === KeyboardKeys.Enter) {
            edit();
        }
    }

    useEffect(() => {
        if (isEmptyPropertie(editCommissionFee?.SIFRA) && isEmptyPropertie(editCommissionFee?.IZNOSOD) && isEmptyPropertie(editCommissionFee?.IZNOSDO) && isEmptyPropertie(editCommissionFee?.IZNOS) && isEmptyPropertie(editCommissionFee?.PROCENAT)) {
            setApplyButton(false);
        } else if (!isEmptyPropertie(editCommissionFee?.SIFRA) && !isEmptyPropertie(editCommissionFee?.IZNOSOD) && !isEmptyPropertie(editCommissionFee?.IZNOSDO) && !isEmptyPropertie(editCommissionFee?.IZNOS) && !isEmptyPropertie(editCommissionFee?.PROCENAT)) {
            setApplyButton(true);
        } else {
            setApplyButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editCommissionFee]);

    const helperValidateOnlyNumbers = (input: string) => {
        if (input.replace(/^\d*\.?\d*$/, '').length > 0) return 'Only numbers allowed!';
    }

    const isEmptyPropertie = (value: string | undefined) => {
        if (value === undefined) {
            return true;
        }
        return value.length === 0 || value === '' ? true : false;
    }

    return <Box sx={style}>
        <div tabIndex={0} className={styles.edit_payment_method_container} onKeyPress={keyboardEvents}>
            <CloseIcon onClick={props.close} className={styles.close_icon} />
            <div className={styles.title_container}>
                <span className={`${styles.filter_icon} icon icon_options`}></span>
                <div>
                    <div className={styles.title}>Edit payout.</div>
                    <div className={styles.subtitle}>Edit existing method of payout.</div>
                </div>
            </div>
            <div className={styles.autocomplete_container}>
                <div>
                    <div className={styles.filter_label}>Code</div>
                    <TextField autoComplete='off' InputProps={{ classes: { disabled: classes.disabled } }} disabled={true} name='SIFRA' value={editCommissionFee.SIFRA || ''} helperText={errorMsgCode} error={errorInputCode} className={styles.filter_field} placeholder={'Code'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 20 }} />
                </div>
                <div>
                    <div className={styles.filter_label}>Amount from</div>
                    <TextField autoComplete='off' name='IZNOSOD' value={editCommissionFee.IZNOSOD} helperText={errorMsgAmountOf} error={errorInputAmountOf} className={styles.filter_field} placeholder={'Amount from'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 10 }} />
                </div>
                <div>
                    <div className={styles.filter_label}>Amount to</div>
                    <TextField autoComplete='off' name='IZNOSDO' value={editCommissionFee.IZNOSDO} helperText={errorMsgAmountTo} error={errorInputAmountTo} className={styles.filter_field} placeholder={'Amount to'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 10 }} />
                </div>
            </div>

            <div className={styles.autocomplete_container}>
                <div>
                    <div className={styles.filter_label}>Amount</div>
                    <TextField autoComplete='off' name='IZNOS' value={editCommissionFee.IZNOS} helperText={errorMsgAmount} error={errorInputAmount} className={styles.filter_field} placeholder={'Amount'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 10 }} />
                </div>
                <div>
                    <div className={styles.filter_label}>Percentage</div>
                    <TextField autoComplete='off' name='PROCENAT' value={editCommissionFee.PROCENAT} helperText={errorMsgPercentage} error={errorInputPercentage} className={styles.filter_field} placeholder={'Percentage'} type='text' variant="outlined" onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)} inputProps={{ maxLength: 5 }} />
                </div>
            </div>

            <div className={styles.buttons_container}>
                <button className={`${styles.apply_button} ${!applyButton ? styles.disabled : ''}`} onClick={edit}>{'SAVE'}</button>
            </div>
        </div>
    </Box>
}

export default EditCommissionFee;