import React, { useContext, useEffect, useState } from 'react';
import styles from './Sidebar.module.scss'
import SidebarItem from './SidebarItem/SidebarItem';
import { SidebarModel } from '../../interface/Sidebar';
import { URLRoutes } from '../../enums/Routes';
import { useHistory, useLocation } from 'react-router-dom';
import { StepContext } from '../StepContext/StepContext';

const Sidebar = (props: any) => {
    const history = useHistory();
    const location = useLocation();
    const [sidebar, setMenuState] = useState({
        sidebarMenu: [
            { label: 'Money Transfer', icon: 'icon_send', active: true, url: [URLRoutes.MoneyTransfer] },
            { label: 'Transaction listing', icon: 'icon_list', active: false, url: [URLRoutes.TransactionListing] },
        ]
    });
    const [visibiltySecondMenu, setVisibiltySecondMenu] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<any>(0);
    const stepNumber = useContext(StepContext);

    const selectMenuItem = (item: SidebarModel, index: number) => {
        setMenuState({
            sidebarMenu: sidebar.sidebarMenu.map((menu, indexItem) => ({
                ...menu,
                active: index === indexItem && item.label === menu.label
            }))
        });

        if (item.url) {
            history.push(item.url[0]); // one sidebar item can have more than one page, so we open the first from the array as default
        }
    }

    useEffect(() => {
        setCurrentStep(props.currentStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentStep]);

    useEffect(() => {
        setVisibiltySecondMenu(location.pathname === URLRoutes.MoneyTransfer);
    }, [location.pathname]);

    return <div className={`${styles.sidebar_wrapper}`}>
        <div className={` ${styles.sidebar_first_menu}`}>
            <div className={styles.sidebar_logo}>
                <span className="icon icon_beoexport"></span>
            </div>
            <div className={styles.menu_container}>
                <div className={styles.sidebar_menu}>
                    {
                        sidebar.sidebarMenu.map((item, index) => {
                            return <SidebarItem key={index} label={item.label} icon={item.icon} active={item.url?.includes(location.pathname as URLRoutes)} click={() => selectMenuItem(item, index)} />
                        })
                    }
                </div>
            </div>
        </div>
        {visibiltySecondMenu ?
            <div className={` ${styles.sidebar_second_menu}`}>
                <div className={styles.sidebar_logo}></div>
                <div className={styles.menu_container}>
                    <div className={styles.sidebar_menu}>

                        <div className={`${styles.sidebar_item_wrapper}  ${currentStep === 0 || currentStep === 1 ? styles.active : ''}`}>
                            {
                                currentStep === 2 || currentStep > 2 ?
                                    <div className={styles.icon_wrapper}>
                                        <span className="icon_svg icon_check_mark"></span>
                                    </div>
                                    :
                                    <div className={styles.icon_wrapper}>
                                        <span className="icon_svg icon_money"></span>
                                    </div>
                            }
                            <div className={`${styles.sidebar_item}`}>TRANSFER AMOUNT</div>
                        </div>

                        <div className={`${styles.sidebar_item_wrapper} ${currentStep === 2 ? styles.active : ''}`}>
                            {
                                currentStep === 3 || currentStep > 3 ?
                                    <div className={styles.icon_wrapper}>
                                        <span className="icon_svg icon_check_mark"></span>
                                    </div>
                                    :
                                    <div className={styles.icon_wrapper}>
                                        <span className="icon_svg icon_transaction_list"></span>
                                    </div>
                            }
                            <div className={`${styles.sidebar_item}`}>TRANSACTION DETAILS</div>
                        </div>

                        <div className={`${styles.sidebar_item_wrapper} ${currentStep === 3 ? styles.active : ''}`}>
                            {
                                currentStep === 4 || currentStep > 4 ?
                                    <div className={styles.icon_wrapper}>
                                        <span className="icon_svg icon_check_mark"></span>
                                    </div>
                                    :
                                    <div className={styles.icon_wrapper}>
                                        <span className="icon_svg icon_recipient"></span>
                                    </div>
                            }
                            <div className={`${styles.sidebar_item}`}>CHOOSE A RECIPIENT</div>
                        </div>

                        <div className={`${styles.sidebar_item_wrapper} ${currentStep === 4 ? styles.active : ''}`}>
                            {
                                currentStep === 5 || currentStep > 5 ?
                                    <div className={styles.icon_wrapper}>
                                        <span className="icon_svg icon_check_mark"></span>
                                    </div>
                                    :
                                    <div className={styles.icon_wrapper}>
                                        <span className="icon_svg icon_checklist"></span>
                                    </div>
                            }
                            <div className={`${styles.sidebar_item}`}>VERIFY DETAILS</div>
                        </div>

                    </div>
                </div>

            </div>
            : null}
    </div>
}

export default Sidebar;