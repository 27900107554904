import React, { useState, useEffect } from 'react';
import { TextField, Popover, Box, InputAdornment } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { DateRangePicker, RangeWithKey, Range } from 'react-date-range';
import moment from 'moment';
import styles from './RangeDatepicker.module.scss'
import DateRangeIcon from '@material-ui/icons/DateRange';

type OnChangeProps = Range | { selection: RangeWithKey } | Date;

interface IRangeDatepicker {
    placeholder: string,
    change: (event: RangeWithKey) => void,
    value?: string
}

const RangeDatepicker = (props: IRangeDatepicker) => {
    const [date, setDatepickerState] = useState<Array<RangeWithKey>>([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }]);
    const [inputRangeValue, setInputRangeValue] = useState<string>('')

    const handleRangeSelect = (ranges: OnChangeProps) => {
        if ('selection' in ranges) {
            const selectedRange = ranges.selection;
            setDatepickerState([selectedRange]);

            if (selectedRange) {
                formatSelectedRange(selectedRange.startDate, selectedRange.endDate)
                if (props.change) {
                    props.change(selectedRange);
                }
            }

        }
    }

    const formatSelectedRange = (startDate?: Date, endDate?: Date) => {
        const dateFormat = 'MMM D, YYYY';

        const formattedStartDate = moment(startDate).format(dateFormat);
        const formattedEndDate = moment(endDate).format(dateFormat);
        setInputRangeValue(formattedStartDate + '-' + formattedEndDate);
    }

    useEffect(() => {
        if (!props.value) {
            setDatepickerState([{
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }]);
            setInputRangeValue('');
        } else {
            const startDate = props.value.split("~")[0];
            const endDate = props.value.split("`")[1];
            const endDateFormatted = moment(endDate).subtract(1, 'days').format('YYYY-MM-DD');

            // IMPORTANT - There are several crazy things that happen with a JS DATE object that convert strings
            // change from "-" to "/" to get correct date
            // "-" format may or may not be ONE DAY OFF depending on YOUR timezone and current time.
            const startDateDash = moment(startDate).format('YYYY/MM/DD');
            const endDateDash = moment(endDateFormatted).format('YYYY/MM/DD');

            setDatepickerState([{
                startDate: new Date(startDateDash),
                endDate: new Date(endDateDash),
                key: 'selection'
            }]);

            formatSelectedRange(new Date(startDateDash), new Date(endDateDash));
        }
    }, [props.value]);

    return <PopupState variant="popover" popupId="datepicker-popup-popover">
        {(popupState) => (
            <div className={styles.range_datepicker_input}>
                <TextField disabled={true} value={inputRangeValue} placeholder={props.placeholder} {...bindTrigger(popupState)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <DateRangeIcon />
                            </InputAdornment>
                        )
                    }} />
                <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <Box p={2}>
                        <DateRangePicker
                            onChange={handleRangeSelect}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            editableDateInputs={true}
                            ranges={date}
                            direction="horizontal"
                        />
                    </Box>
                </Popover>
            </div>
        )}
    </PopupState>
}

export default RangeDatepicker;