import React, { useEffect, useState } from "react";
import styles from './Header.module.scss'
import { Link, useLocation } from "react-router-dom";
import { URLRoutes } from "../../enums/Routes";
import Auth from "../../services/Auth";

const Header = () => {
    const location = useLocation();
    const pages = [
        { title: 'Profile', url: URLRoutes.Profile },
        { title: 'About Us', url: URLRoutes.AboutUs },
        { title: 'Money Transfer', url: URLRoutes.MoneyTransfer },
        { title: 'Transaction Listing', url: URLRoutes.TransactionListing }
    ];
    const [visibiltyAdminMenu, setVisibiltyAdminMenu] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>('');
    const [fullName, setFullName] = useState<string>('');

    useEffect(() => {
        let adminLevelString = localStorage.getItem('auth');
        let adminLevel = adminLevelString && adminLevelString.length > 0 ? JSON.parse(adminLevelString) : '';
        let userNameFromLogin = adminLevelString && adminLevelString.length > 0 ? JSON.parse(adminLevelString).ime : '';

        let userNameLastName = adminLevelString && adminLevelString.length > 0 ? JSON.parse(adminLevelString).prezime : '';
        let fullName = userNameFromLogin + " " + userNameLastName
        let uppercaseLetters = fullName.split(' ').map(function (item: any) { return item[0] }).join('');

        setUserName(userNameFromLogin);
        setFullName(uppercaseLetters.toUpperCase());
        setVisibiltyAdminMenu(Number(adminLevel && adminLevel.ov ? adminLevel.ov : 0) === 3 ? true : false);
    }, [location.pathname]);

    return <div className={`${styles.header_wrapper}`}>
        <div className={styles.header_section}>
            <div className={styles.header_content}>
                {
                    pages.filter(x => x.url === location.pathname).map((item, index) => {
                        return <p key={index}>{item.title}</p>
                    })
                }
                <span>Hi, {userName}</span>
            </div>
            {
                !visibiltyAdminMenu ?
                    <div className={styles.header_links}>
                        <Link to={URLRoutes.MoneyTransfer}>Home</Link>
                        <Link to={URLRoutes.Profile}>Profile</Link>
                        <Link to={URLRoutes.AboutUs}>About Us</Link>
                    </div>
                    : null
            }
            <div className={styles.header_user_profile}>
                <span className={styles.header_icon_wrapper}>{fullName}</span>
                <div className={styles.header_user_content}>
                    <div onClick={() => Auth.logout()} className={styles.header_logout_section}>
                        <p>Logout</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Header;