class LocalStorageService {
  getAuth() {
    const authData = localStorage.getItem('auth');
    if (authData) {
      return JSON.parse(authData);
    }
  }

  setAuthData = (tokenObj: string) => {
    localStorage.setItem('auth', tokenObj);
  }

  getAccessToken = () => {
    return this.getAuth()['token'];
  }

  getRefreshToken = () => {
    return this.getAuth()['refreshToken'];
  }
};

export default new LocalStorageService();