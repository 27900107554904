import React from 'react';
import './App.scss';
import Routing from './components/Routing/Routing';
import {
  RecoilRoot
} from 'recoil';

function App() {
  return (
    <div className="App" >
      <RecoilRoot>
        <Routing />
      </RecoilRoot>
    </div>
  );
}

export default App;
