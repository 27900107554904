import axios from './config/axios';

class UserTransactions {

    getUserTransactions = (id: string, token: string, page: number, orderBy: string, sortBy: string, filter: any) => {
        return axios({
            method: 'post',
            data: filter,
            url: `/transaction/data/all-user-transaction/${id}/${page}/${orderBy}/${sortBy}`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getPaymentMethod = (token: string,) => {
        return axios({
            method: 'get',
            url: `/calculator/reason/transfer`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getReasonForTransfe = (token: string,) => {
        return axios({
            method: 'get',
            url: `/calculator/payment/method`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getPayoutMethod = (token: string,) => {
        return axios({
            method: 'get',
            url: `/calculator/payout/method`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


    getExchangeRate = (token: string,) => {
        return axios({
            method: 'get',
            url: `/calculator/course`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getFee = (token: string,) => {
        return axios({
            method: 'get',
            url: `/calculator/discount`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getDiscount = (token: string, fee: number) => {
        return axios({
            method: 'post',
            url: `/calculator/free`,
            data: { discount: fee },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getSearchRecipient = (token: string, id: string, name: string) => {
        return axios({
            method: 'post',
            url: `/calculator/search/recipient/name`,
            data: { name: name, id: id },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getBalkanCountry = (token: string,) => {
        return axios({
            method: 'get',
            url: `/calculator/balkan_country`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    createNewRecipient = (token: string, recipientData: any) => {
        return axios({
            method: 'post',
            data: recipientData,
            url: `/recipeint/new`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    sendNewTransaction = (token: string, sendData: any) => {
        return axios({
            method: 'post',
            data: sendData,
            url: `/new/transaction/add`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }

    getRecipientForUser = (token: string, userId: any) => {
        return axios({
            method: 'post',
            data: { id: userId },
            url: `/calculator/recipient/list`,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }


}
export default new UserTransactions();