import React, { useEffect, useState, MouseEvent, forwardRef, useImperativeHandle } from 'react';
import styles from './PurposeOfTransferTable.module.scss'
import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TableSortLabel } from '@material-ui/core';
import { PageSizeParameters } from '../../../enums/Enums';
import { useLocation } from 'react-router-dom';
import { IHeaderCells, IPurposeOfTransfer } from '../../../interface/Table';
import BeoExportTableContainer from '../BeoExportTableContainer/BeoExportTableContainer';
import { LocalStorage } from '../../../enums/LocalStorage';
import AdminService from '../../../services/Admin';
import Loading from '../../Loading/Loading';

const PurposeOfTransferTable = forwardRef((props: { editOption?: (arg0: any) => any, rowSelection?: (selected: string[]) => void, }, ref) => {
    type Order = 'asc' | 'desc';
    const [data, setPurposeTransferData] = useState<IPurposeOfTransfer[]>([]);
    const location = useLocation();
    const queryString = require('query-string');
    const [page, setPage] = useState<number>(queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0);
    const [count, setCount] = useState<number>(0);
    const [order, setOrder] = useState<Order>(queryString.parse(location.search).order_by ? queryString.parse(location.search).order_by.split(' ')[1] : 'asc');
    const [orderBy, setOrderBy] = useState<string>(queryString.parse(location.search).order_by ? queryString.parse(location.search).order_by.split(' ')[0] : 'ID');
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const headCells = [
        { key: 'ID', label: 'Serial number' },
        { key: 'SIFRA', label: 'Code' },
        { key: 'SVRHA_TRANSFERA ', label: 'Purpose of transfer' },
        { key: '', label: '' },
    ];

    useEffect(() => {
        getPurposeOfTransfer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderBy, page, order]);

    const getPurposeOfTransfer = async () => {
        const localStorageDataAuth = localStorage.getItem(LocalStorage.Auth);
        const token = localStorageDataAuth ? JSON.parse(localStorageDataAuth).token : null;
        setLoading(true);
        const pageNum = page + 1;
        const sortByParam = order;

        AdminService.getPurposeOfTransfer(token, pageNum, orderBy, sortByParam).then((response) => {
            setLoading(false);
            const { data } = response;
            setPurposeTransferData(data && data.result ? data.result : []);
            setCount(data.totalCount);
        }, error => {
            setLoading(false);
        })
    }

    const createSortHandler = (property?: string) => (event: MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');

        if (property) {
            setOrderBy(property);
            resetFilter();
        }
    };

    const resetFilter = () => {
        setPage(0);
        setCount(0);
    }

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        getRefresData() {
            getPurposeOfTransfer();
        }
    }));

    const editOption = (option: any) => {
        if (props.editOption) {
            props.editOption(option);
        }
    }

    const handleSelection = (Id: string) => {
        const checked = selectedRows.length > 0 ? selectedRows.includes(Id) : false;
        setSelectedRows(!checked ? [Id] : []);
    }

    const isSelected = (Id: string) => selectedRows.indexOf(Id) !== -1;

    useEffect(() => {
        if (props.rowSelection) {
            props.rowSelection(selectedRows);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);


    return <div className={styles.admin_purpose_transfer_table_wrapper}>
        <div className={styles.admin_purpose_transfer_table_header}>
            <div className={styles.recent_options}>Purpose of transfer shown</div>
            <TablePagination component="div" count={count} page={count ? page : 0} onPageChange={(event: MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage++)}
                labelRowsPerPage='' rowsPerPage={PageSizeParameters.PageSizeTen} rowsPerPageOptions={[]} />
        </div>
        <BeoExportTableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {headCells.map((cell: IHeaderCells, index) => (
                            <TableCell key={index}>
                                {cell.key ?
                                    <TableSortLabel
                                        active={orderBy === cell.key}
                                        direction={orderBy === cell.key ? order : 'asc'}
                                        onClick={createSortHandler(cell.key)}>
                                        {cell.label}
                                    </TableSortLabel> : cell.label
                                }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow key={index} onClick={() => handleSelection(item.ID)} className={`${isSelected(item.ID) ? styles.selected : ''}`}>
                            <TableCell>{item.ID}</TableCell>
                            <TableCell>{item.SIFRA}</TableCell>
                            <TableCell>{item.SVRHA_TRANSFERA}</TableCell>
                            <TableCell onClick={() => editOption(item)}>
                                <span className={`icon_svg icon_edit`}></span>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </BeoExportTableContainer>
        <Loading loading={loading} />
    </div>
})

export default PurposeOfTransferTable;