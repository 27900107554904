import Alert from '@material-ui/lab/Alert';
import React, { useState, useEffect } from "react";
import { ISnackbar } from '../../interface/Snackbar';
import Snackbar from '@material-ui/core/Snackbar';
import styles from './SnackbarAlert.module.scss'

const SnackbarAlert = (props: ISnackbar) => {
    const [open, setOpen] = useState<boolean>(props.open);
    const vertical = 'top';
    const horizontal = 'right';

    const close = () => {
        setOpen(false);
    }

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);    

    return <Snackbar open={open} autoHideDuration={5000} onClose={close} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} className={styles.snackbar_material}>
                <Alert severity={props.severity} onClose={close}>
                    {props.message}
                </Alert>
            </Snackbar>
}

export default SnackbarAlert;