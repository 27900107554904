import React from 'react';
import styles from './AdminSidebarItem.module.scss'
import { SidebarModel } from '../../../interface/Sidebar';

const AdminSidebarItem = (props: SidebarModel) => {
    return <div onClick={props.click} className={`${styles.sidebar_item_wrapper} ${props.active ? styles.active : ''}`}>
        <div className={styles.icon_wrapper}>
            <span className={props.icon + " icon_svg"}></span>
        </div>
        <div className={`${styles.sidebar_item}`}>{props.label}</div>
    </div>
}

export default AdminSidebarItem;